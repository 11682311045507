<template>
  <div class="brigades">
    <div class="page_header">
      <h1>Бригады</h1>
      <el-button type="primary" size="mini" @click="create" icon="el-icon-plus">Добавить</el-button>
    </div>
    <div class="page_content" v-loading="loading">
      <div class="list" v-if="brigades">
        <table class="table">
          <thead>
          <tr>
            <th>#</th>
            <th>Бригада</th>
            <th>Монтажники</th>
            <th>Руководитель</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(brigade, idx) in brigades" :key="brigade.id">
            <td>{{ ++idx }}</td>
            <td>
              {{ brigade.title }}
            </td>
            <td>
                  <span v-if="brigade.employeesData">
                    <el-tag size="mini" v-for="(e, idx) in brigade.employeesData" :key="idx">
                      {{ e.employee.fio_short }}
                    </el-tag>
                  </span>
            </td>
            <td>
              <el-tag size="mini" v-if="brigade.leaderData">
                {{ brigade.leaderData.employee.fio_short }}
              </el-tag>
            </td>
            <td>
              <el-button
                  type="primary"
                  size="mini"
                  @click="openDrawer(brigade)"
                  icon="el-icon-edit">Изменить
              </el-button>
              <el-popconfirm
                  @confirm="remove(brigade.id)"
                  confirm-button-text='Да'
                  cancel-button-text='Нет'
                  title="Вы действительно хотите удалить?">
                <el-button
                    slot="reference"
                    icon="el-icon-delete"
                    size="mini" type="danger"
                    style="margin-left: 5px;"></el-button>
              </el-popconfirm>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div v-else>
        <p>Нет данных для отображения</p>
      </div>

    </div>

    <el-drawer
        :visible.sync="drawerActive"
        direction="rtl"
        size="30%"
        custom-class="drawer_box"
        :before-close="closeDrawer"
        :destroy-on-close="true"
        :show-close="false"
        :wrapperClosable="false"
        :withHeader="false"
        :append-to-body="true"
        class="settings_drawer"
    >
      <div class="drawer_header">
        <h3 class="title font-ui">Бригада</h3>
        <button class="close_btn" @click="closeDrawer">
          <i class="el-icon-close"></i>
        </button>
      </div>

      <div class="drawer_content" v-if="formData" v-loading="submitting">
        <el-form :model="formData" :rules="formDataRules" ref="formData" class="form"
                 @submit.native.prevent="formDataAction('formData')">
          <el-form-item label="Название бригады" prop="title">
            <el-input v-model="formData.title" size="mini"></el-input>
          </el-form-item>

          <el-form-item label="Состав бригады" prop="employees">
            <el-checkbox-group v-model="formData.employees">
              <el-checkbox
                  v-for="fitter in fitters"
                  :key="fitter.id"
                  :label="fitter.id">
                {{ fitter.fio_full }}
              </el-checkbox>
            </el-checkbox-group>
          </el-form-item>

          <el-form-item label="Руководитель бригады" prop="leader">
            <el-radio-group v-model="formData.leader">
              <el-radio
                  v-for="(leader, idx) in fitters"
                  :key="idx"
                  :label="leader.id">
                {{ leader.fio_full }}
              </el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="Своя бригада" prop="own">
            <el-switch v-model="formData.own"></el-switch>
          </el-form-item>

          <el-form-item label="Комментарий" prop="comment">
            <el-input
                type="textarea"
                :autosize="{ minRows: 2 }"
                placeholder="Комментарий"
                v-model="formData.comment"
                size="mini"></el-input>
          </el-form-item>

          <el-form-item>
            <el-button
                type="primary"
                size="mini"
                @click="formDataAction('formData')">Сохранить
            </el-button>
            <el-button size="mini" @click="closeDrawer">Отменить</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import {mapState} from 'vuex'

import {actionTypes as brigadeActions} from "@/store/modules/brigades"

export default {
  name: "Brigades",
  data() {
    return {
      drawerActive: false,
      formData: null,
      formDataRules: {
        title: [
          {required: true, message: 'Введите название бригады', trigger: 'blur'}
        ],
        employees: [
          {required: true, message: 'Выберите монтажников', trigger: 'change'}
        ],
        leader: [
          {required: true, message: 'Выберите руководителя', trigger: 'change'}
        ]
      }
    }
  },
  computed: {
    ...mapState({
      brigades: state => state.brigades.list,
      loading: state => state.brigades.loading,
      submitting: state => state.brigades.submitting,
      employees: state => state.employees.list,
    }),
    fitters: function () {
      if (this.employees != null && this.employees.length != 0) {
        return _.filter(this.employees, function (employee) {
          if (employee.user.role == 'fitter') {
            return employee
          }
        })
      } else {
        return;
      }
    }
  },
  methods: {
    create() {
      this.formData = {
        title: '',
        employees: [],
        leader: null,
        own: true,
        comment: '',
      }
      this.drawerActive = true
    },
    openDrawer(brigade) {
      this.formData = _.cloneDeep(brigade)
      this.drawerActive = true
    },
    closeDrawer() {
      this.drawerActive = false
      this.formData = null
    },
    formDataAction(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$store.dispatch(brigadeActions.save, this.formData).then(() => {
            this.loadBrigades()
            this.drawerActive = false
            this.formData = null
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    remove(id){
      this.$store.dispatch(brigadeActions.remove, id).then(() => {
        this.loadBrigades()
      })
    },
    loadBrigades() {
      this.$store.dispatch(brigadeActions.get)
    }
  },
  watch: {

  },
  created() {
    document.title = this.$route.meta.title ? this.$route.meta.title : 'GlassERP'
  }
}
</script>
